import {
  ascendantBlot,
  Blot,
  Condition,
  descendantBlots,
  InlineCondition,
  Matholder,
  type MountedEditor,
  Placeholder,
  Query,
  Repeater,
  TableRow
} from '@avvoka/editor'
import { getObjectEntries } from '@avvoka/shared'
import Ast, { type AstBox, type SuperAstType } from './ast/ast'

export enum QuestionnaireLoopResolution {
  Legacy = 'legacy',
  Modern = 'modern'
}

export const repeaterKeys = [
  'repeater-id',
  'repeater-master-id',
  'repeater-name',
  'repeater-label',
  'loop_control',
  'repeater-merge',
  'loop_label'
] as const

const legacyMode = (
  questions: Backend.Questionnaire.IQuestion[],
  operations: Array<{ cond?: string; att: string }>,
  editor: MountedEditor
) => {
  const [placeholders, conditions, repeaters, tableRepeaters] = editor.querySet(
    [
      Query<Placeholder>('placeholder'),
      Query<Condition | InlineCondition>('condition', 'icondition'),
      Query<Repeater>('repeater'),
      Query<TableRow>('tr').WithAttributes('data-repeater-id')
    ] as const
  )

  repeaters.concat(tableRepeaters).forEach((repeater: Blot) => {
    const repeaterId = repeater.attributes['data-repeater-id']
    const repeaterName = repeater.attributes['data-repeater-name']
    const repeaterLabel = repeater.attributes['data-repeater-label']
    const repeaterMasterId = repeater.attributes['data-repeater-master-id']
    const repeaterMerge = repeater.attributes['data-repeater-merge']

    const loopPlaceholders = descendantBlots(
      repeater,
      0,
      repeater.length(),
      (blot) => blot.statics.blotName === 'placeholder'
    )
    const conditions = descendantBlots(repeater, 0, repeater.length(), (blot) =>
      ['condition', 'condition'].includes(blot.statics.blotName)
    )
    const matholders = descendantBlots(
      repeater,
      0,
      repeater.length(),
      (blot) => blot.statics.blotName === 'matholder'
    )

    const makeRepeaterQuestion = (
      question: Backend.Questionnaire.IQuestion | null
    ) => {
      if (!question || !question.opts) return
      if (
        question.opts['repeater-id'] == null ||
        question.opts['repeater-id'] !== repeaterId
      )
        question.opts['repeater-id'] = repeaterId
      if (
        question.opts['repeater-name'] == null ||
        question.opts['repeater-name'] !== repeaterName
      )
        question.opts['repeater-name'] = repeaterName
      if (
        question.opts['repeater-label'] == null ||
        question.opts['repeater-label'] !== repeaterLabel
      )
        question.opts['repeater-label'] = repeaterLabel
      if (
        question.opts['repeater-master-id'] == null ||
        question.opts['repeater-master-id'] !== repeaterMasterId
      )
        question.opts['repeater-master-id'] = repeaterMasterId
      if (
        question.opts['repeater-merge'] == null ||
        question.opts['repeater-merge'] !== repeaterMerge
      )
        question.opts['repeater-merge'] = repeaterMerge
    }
    loopPlaceholders.forEach((placeholder) => {
      const attr = placeholder.node?.textContent as string
      const qIndex = questions.findIndex((q) => q.att === attr)
      const question = questions[qIndex]
      makeRepeaterQuestion(question)
      if (placeholder.attributes['data-repeater-id'] !== repeaterId)
        placeholder.attributes['data-repeater-id'] = repeaterId
    })
    conditions.forEach((condition) => {
      const ast = Ast.parse(condition.attributes['data-condition'])
      if (ast != null) {
        const attributes = Ast.traverse(ast).attributes
        attributes.forEach((attribute) => {
          const question = questions.find((q) => q.att === attribute)!
          const placeholder = placeholders.find(
            (blot) => blot.node?.textContent === attribute
          )
          if (
            !placeholder ||
            (placeholder.attributes['data-repeater-id'] &&
              placeholder.attributes['data-repeater-id'] === repeaterId)
          )
            makeRepeaterQuestion(question)
        })
      }
    })
    matholders.forEach((matholder) => {
      const isExpression = matholder.attributes['data-expression'] != null
      const operation = operations.find(
        (op) => op.att === matholder.node?.textContent
      )!
      const parsedAst = Ast.parse(
        isExpression
          ? matholder.attributes['data-expression']
          : (operation.cond as string)
      )
      if (parsedAst) {
        const attributes = Ast.traverse(parsedAst)?.attributes ?? []
        attributes.forEach((attribute) => {
          const hasPlaceholderOutsideLoop = placeholders
            .filter(
              (placeholder) => placeholder.node?.textContent === attribute
            )
            .some(
              (placeholder) =>
                !loopPlaceholders
                  .map(
                    (loopPlaceholder) =>
                      loopPlaceholder.node?.textContent as string
                  )
                  .includes(placeholder.node?.textContent as string)
            )
          if (!hasPlaceholderOutsideLoop) {
            const question = questions.find((q) => q.att === attribute)
            if (question) makeRepeaterQuestion(question)
          }
        })
      }
    })
  })

  const repeaterKeys = [
    'repeater-id',
    'repeater-master-id',
    'repeater-name',
    'repeater-label',
    'loop_control',
    'repeater-merge',
    'loop_label'
  ] as const

  const unmakeRepeaterQuestion = (
    question: Backend.Questionnaire.IQuestion
  ) => {
    repeaterKeys.forEach((key) => (question.opts[key] = undefined))
  }

  placeholders.forEach((placeholder) => {
    const attr = placeholder.node?.textContent as string
    const qIndex = questions.findIndex((q) => q.att === attr)
    const question = questions[qIndex]
    if (!question) return
    const isNotRepeaterAnymore = !placeholder.attributes['data-repeater-id']
    const hasRepeaterKey = Object.keys(question.opts).some((key) =>
      repeaterKeys.includes(key)
    )
    if (hasRepeaterKey && isNotRepeaterAnymore) unmakeRepeaterQuestion(question)
  })

  conditions.forEach((condition) => {
    const getParentOfType = (
      blot: Blot,
      blotName: string,
      includeSelf = false
    ): Blot | null => {
      // can we figure out a way how to reuse from utils??
      if (blot == null) return blot ?? null
      if (includeSelf && blotName === blot.statics.blotName) return blot
      if (blot.parent != null) {
        const parent = blot.parent
        if (parent.statics.blotName === blotName) return parent
        return getParentOfType(parent, blotName)
      }
      return null
    }

    const ast = Ast.parse(condition.attributes['data-condition'] ?? '{}')
    if (ast != null) {
      const attributes = Ast.traverse(ast).attributes

      attributes.forEach((attribute) => {
        const attPlaceholders = placeholders.filter(
          (blot) => blot.node?.textContent === attribute
        )
        const shouldUnmake = attPlaceholders.length
          ? attPlaceholders.every(
              (placeholder) =>
                !getParentOfType(placeholder, 'repeater') &&
                !getParentOfType(placeholder, 'tr')?.attributes[
                  'data-repeater-id'
                ]
            )
          : !getParentOfType(condition, 'repeater') &&
            !getParentOfType(condition, 'tr')?.attributes['data-repeater-id']
        if (shouldUnmake) {
          const question = questions.find((q) => q.att === attribute)
          if (question) unmakeRepeaterQuestion(question)
        }
      })
    }
  })
}

const modernMode = (
  questions: Backend.Questionnaire.IQuestion[],
  operations: Array<{ cond?: string; att: string }>,
  editor: MountedEditor
) => {
  const unmakeRepeaterQuestion = (
    question: Backend.Questionnaire.IQuestion
  ) => {
    repeaterKeys.forEach((key) => (question.opts[key] = undefined))
    question.opts = { ...question.opts } // Force reactivity
  }

  const makeRepeaterQuestion = (
    question: Backend.Questionnaire.IQuestion,
    loopBlot: Repeater | TableRow
  ) => {
    const loopId = loopBlot.attributes['data-repeater-id']
    const loopName = loopBlot.attributes['data-repeater-name']
    const loopLabel = loopBlot.attributes['data-repeater-label']
    const loopMasterId = loopBlot.attributes['data-repeater-master-id']
    const loopMerge = loopBlot.attributes['data-repeater-merge']

    question.opts ??= {}
    if (
      question.opts['repeater-id'] == null ||
      question.opts['repeater-id'] !== loopId
    )
      question.opts['repeater-id'] = loopId
    if (
      question.opts['repeater-name'] == null ||
      question.opts['repeater-name'] !== loopName
    )
      question.opts['repeater-name'] = loopName
    if (
      question.opts['repeater-label'] == null ||
      question.opts['repeater-label'] !== loopLabel
    )
      question.opts['repeater-label'] = loopLabel
    if (
      question.opts['repeater-master-id'] == null ||
      question.opts['repeater-master-id'] !== loopMasterId
    )
      question.opts['repeater-master-id'] = loopMasterId
    if (
      question.opts['repeater-merge'] == null ||
      question.opts['repeater-merge'] !== loopMerge
    )
      question.opts['repeater-merge'] = loopMerge
    question.opts = { ...question.opts } // Force reactivity
  }

  const [placeholders, conditions, mathholders, repeaters, tableRepeaters] =
    editor.querySet([
      Query<Placeholder>('placeholder'),
      Query<Condition | InlineCondition>('condition', 'icondition'),
      Query<Matholder>('matholder'),
      Query<Repeater>('repeater'),
      Query<TableRow>('tr').WithAttributes('data-repeater-id')
    ] as const)

  // We need to differentiate between linked and unlinked loops
  // Linked loops are loops that have (data-repeater-master-id) or (data-repeater-master-id is pointing to a data-repeater-id which will be linked to a master loop)
  const slaveLoops: Record<string, string> = {}
  const loopsById: Record<string, Repeater | TableRow> = {}

  repeaters.forEach((repeater) => {
    const loopId = repeater.attributes['data-repeater-id']
    loopsById[loopId] = repeater
    const loopMasterId = repeater.attributes['data-repeater-master-id']
    if (loopMasterId != null) {
      slaveLoops[loopId] = loopMasterId
    }
  })
  tableRepeaters.forEach((tableRepeater) => {
    const loopId = tableRepeater.attributes['data-repeater-id']
    loopsById[loopId] = tableRepeater
    const loopMasterId = tableRepeater.attributes['data-repeater-master-id']
    if (loopMasterId != null) {
      slaveLoops[loopId] = loopMasterId
    }
  })
  const masterLoops = new Set<string>()
  Object.keys(slaveLoops).forEach((slaveLoopId) => {
    const masterLoopId = slaveLoops[slaveLoopId]
    masterLoops.add(masterLoopId)
  })

  enum LoopType {
    NONE,
    LINKED,
    UNLINKED
  }

  const getLoopType = (
    blot: Blot
  ): { type: LoopType; id: string; masterId: string } => {
    const parentRepeater = ascendantBlot(
      blot,
      (b) => b.statics.blotName === 'repeater'
    )
    const parentTableRow = ascendantBlot(
      blot,
      (b) => b.statics.blotName === 'tr'
    )
    const attributes =
      parentRepeater?.attributes ?? parentTableRow?.attributes ?? {}
    if (attributes['data-repeater-id'] !== undefined) {
      const loopId = attributes['data-repeater-id']
      if (masterLoops.has(loopId) || slaveLoops[loopId] !== undefined) {
        return {
          type: LoopType.LINKED,
          id: loopId,
          masterId: slaveLoops[loopId] ?? loopId
        }
      } else {
        return { type: LoopType.UNLINKED, id: loopId, masterId: '' }
      }
    }
    return { type: LoopType.NONE, id: '', masterId: '' }
  }

  const attributesOccurrences: Record<
    string,
    {
      placeholder: number
      condition: number
      linkedLoop: number
      unlinkedLoop: number
      operation: number

      masterLoop?: Repeater | TableRow
      loop?: Repeater | TableRow

      outOfLoop?: boolean

      // Asts that contain the attribute
      asts?: AstBox[]
    }
  > = {}

  const ensureAttributeOccurrences = (attribute: string) => {
    attributesOccurrences[attribute] ??= {
      placeholder: 0,
      condition: 0,
      linkedLoop: 0,
      unlinkedLoop: 0,
      operation: 0,
      asts: []
    }
  }

  const loopAtt: Record<string, Set<string>> = {}
  const ensureLoopAtt = (loopId: string) => {
    loopAtt[loopId] ??= new Set()
  }

  const addLoopAtt = (
    loop: ReturnType<typeof getLoopType>,
    attribute: string,
    occurance: typeof attributesOccurrences
  ) => {
    ensureLoopAtt(loop.id)
    if (loopAtt[loop.id].has(attribute)) return
    loopAtt[loop.id].add(attribute)
    // NOTE: `loopsById[loop.masterId] != null` When a master loop doesn't exist anymore, we will unlink the loop and connect it to its own loop
    if (loop.type === LoopType.LINKED && loopsById[loop.masterId] != null) {
      occurance[attribute].linkedLoop++
      occurance[attribute].masterLoop = loopsById[loop.masterId]
    } else {
      occurance[attribute].unlinkedLoop++
      // Prevent overwriting loop, as we want to keep the first loop we encounter (i.e. placeholder loop has priority over operation loop)
      if (occurance[attribute].loop == null) {
        occurance[attribute].loop = loopsById[loop.id]
      }
    }
  }

  placeholders.forEach((placeholder) => {
    const attribute = placeholder.node?.textContent as string
    ensureAttributeOccurrences(attribute)
    attributesOccurrences[attribute].placeholder++

    const loop = getLoopType(placeholder)
    if (loop.type !== LoopType.NONE) {
      addLoopAtt(loop, attribute, attributesOccurrences)
    } else {
      attributesOccurrences[attribute].outOfLoop = true
    }
  })

  conditions.forEach((condition) => {
    const loop = getLoopType(condition)

    Ast.parseMaybe(condition.attributes['data-condition']).ifPresent((ast) => {
      const attributes = Ast.traverse(ast).attributes
      attributes.forEach((attribute) => {
        ensureAttributeOccurrences(attribute)
        attributesOccurrences[attribute].condition++
        attributesOccurrences[attribute].asts?.push(ast)

        if (loop.type !== LoopType.NONE) {
          addLoopAtt(loop, attribute, attributesOccurrences)
        } else {
          attributesOccurrences[attribute].outOfLoop = true
        }
      })
    })
  })

  const operationsByAtt = operations.reduce<Record<string, string>>(
    (acc, op) => ((acc[op.att] = op.cond as string), acc),
    {}
  )
  mathholders.forEach((mathholder) => {
    const attribute = mathholder.node?.textContent as string
    const isExpression = mathholder.attributes['data-expression'] != null
    const loop = getLoopType(mathholder)
    Ast.parseMaybe(
      isExpression
        ? mathholder.attributes['data-expression']
        : operationsByAtt[attribute]
    ).ifPresent((ast) => {
      const attributes = Ast.traverse(ast).attributes
      attributes.forEach((attribute) => {
        ensureAttributeOccurrences(attribute)
        attributesOccurrences[attribute].operation++
        attributesOccurrences[attribute].asts?.push(ast)

        if (loop.type !== LoopType.NONE) {
          const occurences = attributesOccurrences[attribute]
          const isInLinkedLoop = occurences.linkedLoop > 0
          const isInUnlinkedLoop = occurences.unlinkedLoop > 0
          const isInLoop = isInLinkedLoop || isInUnlinkedLoop

          const isInPlaceholder = occurences.placeholder > 0
          const isInCondition = occurences.condition > 0
          const isInPlaceholderOrCondition = isInPlaceholder || isInCondition

          if (
            (isInPlaceholderOrCondition && isInLoop) ||
            !isInPlaceholderOrCondition
          ) {
            addLoopAtt(loop, attribute, attributesOccurrences)
          }
        } else {
          attributesOccurrences[attribute].outOfLoop = true
        }
      })
    })
  })

  const questionsByAtt = questions.reduce<
    Record<string, Backend.Questionnaire.IQuestion>
  >((acc, q) => ((acc[q.att as string] = q), acc), {})

  getObjectEntries(attributesOccurrences).forEach(
    ([attribute, occurrences]) => {
      const question = questionsByAtt[attribute]
      if (!question) return

      const inCondition = occurrences.condition > 0
      const inOperation = occurrences.operation > 0
      const inUnlinkedLoop = occurrences.unlinkedLoop > 0
      const inLinkedLoop = occurrences.linkedLoop > 0
      const inPlaceholder = occurrences.placeholder > 0
      const inLoop = occurrences.linkedLoop + occurrences.unlinkedLoop > 0
      const inMultipleLoops =
        occurrences.linkedLoop + occurrences.unlinkedLoop > 1
      const inOutsideLoop = occurrences.outOfLoop === true

      // Placeholder only
      if (inPlaceholder && !inCondition && !inOperation) {
        // If attribute is in a placeholder in a loop and in another placeholder in a linked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inLinkedLoop &&
          !inUnlinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.masterLoop!)
        }

        // If attribute is in a placeholder in a loop and in another placeholder in an unlinked loop
        // - appear unlooped in the DQ
        if (
          inLoop &&
          inUnlinkedLoop &&
          !inLinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return unmakeRepeaterQuestion(question)
        }

        // If attribute is in a placeholder(s) in a loop
        // - appear looped in the DQ
        if (inLoop && !inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }

        // If attribute is in a placeholder in a loop and in another placeholder outside a loop
        // - appear unlooped in the DQ
        if (inLoop && inOutsideLoop) {
          return unmakeRepeaterQuestion(question)
        }
      }

      // Condition only
      if (!inPlaceholder && inCondition && !inOperation) {
        // if attribute is in a condition in a loop and in another condition in a linked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inLinkedLoop &&
          !inUnlinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.masterLoop!)
        }

        // if attribute is in a condition in a loop and in another condition in an unlinked loop
        // - appear unlooped in the DQ
        if (
          inLoop &&
          inUnlinkedLoop &&
          !inLinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return unmakeRepeaterQuestion(question)
        }

        // if attribute is in a condition(s) in a loop
        // - appear looped in the DQ
        if (inLoop && !inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }

        // if attribute is in a condition in a loop and in another condition outside a loop
        // - appear unlooped in the DQ
        if (inLoop && inOutsideLoop) {
          return unmakeRepeaterQuestion(question)
        }
      }

      // Operation only
      if (!inPlaceholder && !inCondition && inOperation) {
        // if attribute is in an operation in a loop and in another operation in a linked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inLinkedLoop &&
          !inUnlinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.masterLoop!)
        }

        // if attribute is in an operation in a loop and in another operation in an unlinked loop
        // - appear unlooped in the DQ
        if (
          inLoop &&
          inUnlinkedLoop &&
          !inLinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return unmakeRepeaterQuestion(question)
        }

        // if attribute is in an operation(s) in a loop
        // - appear looped in the DQ
        if (inLoop && !inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }

        // if attribute is in an operation in a loop and in another operation outside the loop
        // - appear unlooped in the DQ
        if (inLoop && inOutsideLoop) {
          return unmakeRepeaterQuestion(question)
        }
      }

      // Placeholder + Condition
      if (inPlaceholder && inCondition && !inOperation) {
        // If attribute is in a placeholder in a loop, and in another condition inside a linked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inLinkedLoop &&
          !inUnlinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.masterLoop!)
        }

        // If attribute is in a placeholder in a loop, and in another condition inside an unlinked loop
        // - appear unlooped in the DQ
        if (
          inLoop &&
          inUnlinkedLoop &&
          !inLinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return unmakeRepeaterQuestion(question)
        }

        // If attribute is in a placeholder in a loop, and in another condition inside the same loop
        // - appear looped in the DQ
        if (inLoop && !inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }

        // If an attribute in a placeholder is looped
        // and the attribute also appears in a condition outside the loop or in an unlinked loop
        // and the condition for the attribute is 'Attribute'  'Present/NotPresent/Contains/NotContains'  'Value'
        // set an exception that if the attribute only appears in conditions that are for one of these 4 operators, that the question continues to be looped.
        if (inLoop && (inOutsideLoop || inUnlinkedLoop)) {
          const conditional = [
            'Present',
            'NotPresent',
            'Contains',
            'NotContains'
          ] as const
          const searchAsts = [
            ...conditional.map((item) => {
              return { [item]: [{ Att: attribute }, { String: '' }] }
            }),
            ...conditional.map((item) => {
              return { [item]: [{ Att: attribute }] }
            })
          ]

          const found = occurrences.asts?.every((ast) => {
            return searchAsts.some((searchAst) => {
              let mismatch = false

              // Traverse the ast until we find the attribute and store the parent ast
              let parentAst: SuperAstType = ast

              Ast.forEach(ast.ast, (ast) => {
                // Prevent foreach from continuing if we already found a mismatch
                if (mismatch) return true

                if (ast.Att === attribute) {
                  if (!Ast.compare(parentAst, searchAst, true)) {
                    mismatch = true
                  }
                } else {
                  parentAst = ast
                }
              })

              return !mismatch
            })
          })

          if (found) {
            return makeRepeaterQuestion(
              question,
              occurrences.loop! ?? occurrences.masterLoop!
            )
          }
        }

        // If attribute is in a placeholder in a loop, and in another condition outside a loop
        // - appear unlooped in the DQ
        if (inLoop && inOutsideLoop) {
          return unmakeRepeaterQuestion(question)
        }
      }

      // Placeholder + Operation
      if (inPlaceholder && inOperation) {
        // if attribute is in a placeholder in a loop and in an operation in a linked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inLinkedLoop &&
          !inUnlinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.masterLoop!)
        }

        // if attribute is in a placeholder in a loop and in an operation in an unlinked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inUnlinkedLoop &&
          !inLinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.loop!)
        }

        // if attribute is in a placeholder in a loop and in an operation in the same loop
        // - appear looped in the DQ
        if (inLoop && !inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }

        // if attribute is in a placeholder in a loop and in an operation outside the loop
        // - appear looped in the DQ
        if (inLoop && inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }
      }

      // Condition + Operation
      if (inCondition && inOperation) {
        // if attribute is in a condition in a loop and in an operation in a linked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inLinkedLoop &&
          !inUnlinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.masterLoop!)
        }

        // if attribute is in a condition in a loop and in an operation in an unlinked loop
        // - appear looped in the DQ
        if (
          inLoop &&
          inUnlinkedLoop &&
          !inLinkedLoop &&
          !inOutsideLoop &&
          inMultipleLoops
        ) {
          return makeRepeaterQuestion(question, occurrences.loop!)
        }

        // if attribute is in a condition in a loop and in an operation in the same loop
        // - appear looped in the DQ
        if (inLoop && !inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }

        // if attribute is in a condition in a loop and in an operation outside the loop
        // - appear looped in the DQ
        if (inLoop && inOutsideLoop) {
          return makeRepeaterQuestion(
            question,
            occurrences.loop! ?? occurrences.masterLoop!
          )
        }
      }

      // No loop at all
      if (question.opts['repeater-id'] != null) {
        return unmakeRepeaterQuestion(question)
      }
    }
  )
}

export const resolveLoopedQuestions = (
  questions: Backend.Questionnaire.IQuestion[],
  operations: Array<{ cond?: string; att: string }>,
  editor: MountedEditor,
  resolution: QuestionnaireLoopResolution = QuestionnaireLoopResolution.Modern
) => {
  if (resolution == QuestionnaireLoopResolution.Legacy) {
    legacyMode(questions, operations, editor)
  } else {
    modernMode(questions, operations, editor)
  }
}
